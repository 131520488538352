@import url("https://fonts.googleapis.com/css2?family=Merriweather&family=Montserrat:wght@400;500;700&display=swap");

html,
body,
#root,
#root > div {
  width: 100%;
  height: 100%;
  margin: 0;
}
input[type="text"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background-color: transparent;
}
input[type="password"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background-color: transparent;
}
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #242d35 inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #fafafa !important;
  font-family: "Merriweather";
}
.app {
  width: 100%;
  height: 100%;
  margin: 0;
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #242d35;
  height: 100%;
  flex-direction: column;
  width: 100%;
}
.login h1 {
  font-family: "Merriweather";
  font-size: 50px;
  color: #fafafa;
  font-weight: 400;
}
.loginContainer {
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loginInput {
  border: 0;
  border-bottom: 1px solid #fafafa;
  width: 350px;
  height: 50px;
  padding-left: 10px;
  background-color: transparent;
  color: #fafafa;
  font-family: "Merriweather";
  font-size: 14px;
  margin-bottom: 12px;
}
.loginInput::placeholder {
  color: #fafafa;
  font-family: "Merriweather";
  font-size: 14px;
}
.login .loginButton {
  margin-top: 80px;
  width: 350px;
  height: 50px;
  border-radius: 36px;
  background-color: transparent;
  border: 1px solid #fafafa;
  font-family: "Merriweather";
  font-size: 14px;
  color: #fafafa;
  cursor: pointer;
}
.forgetPasswordLink {
  margin-top: 30px;
  font-family: Merriweather;
  font-size: 14px;
  color: #fafafa;
  text-decoration: none;
}
.forgetPasswordLink:hover {
  text-decoration: underline;
}
.forgetPasswordLink:visited,
.forgetPasswordLink:active {
  text-decoration: none;
  color: #fafafa;
}
.mainHolder {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

/* NAVIGATION */

.navComponent {
  width: 240px;
  height: 100%;
  background-color: #0c1116;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
}
.navComponent h3 {
  font-family: "Merriweather";
  font-size: 20px;
  color: #fafafa;
  margin-top: 25px;
  margin-bottom: 25px;
}
.navElementHolder {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
}
.navElement {
  width: 99%;
  height: 48px;
  color: #fafafa;
  font-family: "Montserrat";
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}
.navElement img {
  margin-right: 24px;
  margin-left: 24px;
}
.navElementHolder .active {
  border-right: 3px solid #6e6ef7;
}
.navBottom {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contentContainer {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-left: 240px;
}
.header {
  width: 90%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  border-bottom: 1px solid #eaecee;
  margin-bottom: 25px;
}
.header h3 {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 24;
}
.header .rightContainer {
  display: flex;
  align-items: center;
}
.header .leftContainer {
  display: flex;
  align-items: center;
}
.rightContainer .username {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 2px solid #9a9a9a;
  border-radius: 25px;
  margin-left: 15px;
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 700;
  color: #242d35;
}

.buttonMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 12px 12px 20px;
  width: 214px;
  height: 38px;
  background: #4040f2;
  border-radius: 32px;
  border: 0;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}
.buttonMain span {
  font-family: "Montserrat";
  font-weight: 500;
  color: #fff;
  font-size: 14px;
}
.unregisteredButton {
  background-color: #fff;

  border: 1px solid #242d35;
}
.unregisteredButton span {
  color: #242d35;
}

.generate {
  border: 0;
  border-bottom: 1px solid #242d35;
  width: 350px;
  height: 50px;
  padding-left: 10px;
  background-color: transparent;
  color: #242d35;
  font-family: "Montserrat";
  font-size: 14px;
  margin-bottom: 12px;
}
.generate::placeholder {
  color: #242d35;
  font-family: "Montserrat";
  font-size: 14px;
}
.table {
  width: 75%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid #fafafa;
  margin-top: 50px;
}
.tableHeader {
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  background-color: #fafafa;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-left: 25px;
}
.tableHeader span {
  width: 400px;
  font-family: "Montserrat";
  font-size: 12px;
  color: #4f5b67;
  font-weight: 500;
}
.tableContentItem {
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  padding-left: 25px;
}
.tableContentItem span {
  width: 400px;
  font-family: "Montserrat";
  font-size: 14px;
  color: #242d35;
}
.email {
  font-size: 12px;
  text-transform: none;
  font-weight: 500;
  color: #757575 !important;
  padding-top: 5px;
}
.nameContainer {
  text-transform: capitalize;
  width: 450px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
}
.codeFix {
  width: 250px !important;
}
.nanniesItem {
  height: 80px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #a9a9a9;
}
.nanniesItem img {
  width: 75px;
  height: 75px;
  border-radius: 37.5px;
}
.nannyPersonal {
  flex-direction: row !important;
}
.nannyPersonal .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
}
.nannyPersonal img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-right: 25px;
}
.nannyBio {
  font-weight: 400 !important;
  margin-bottom: 15px !important;
}
.editBio {
  font-weight: 400 !important;
}
.nannyBioContainer {
  height: auto !important;
  min-height: 125px !important;
}
.booking span {
  width: 200px;
}
.star {
  color: #f5c643;
}
.clientDetailContainer {
  width: 90%;
}
.clientDetailContainer .infoContainer,
.notificationInputBox .infoContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  border: 1px solid #a8b0b9;
  border-radius: 25px;
  padding-left: 25px;
  position: relative;
  height: 125px;
  padding-top: 30px;
  margin-bottom: 50px;
}
.infoContainer .title {
  position: absolute;
  top: -10px;
  background-color: #fff;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  color: #4f5b67;
  margin: 0;
}
.infoContainer .name {
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.infoContainer span {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 5px;
  padding-left: 15px;
}

.bookings {
  min-height: 125px;
  height: auto !important;
}
.bookings .bookingItem {
  height: 75px;
  margin-bottom: 15px;
  border-bottom: 1px solid #9a9a9a;
  margin-right: 35px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.bookingItem span {
  font-weight: 400;
}
.bookingItem img {
  border-radius: 37.5px;
  width: 75px;
  height: 75px;
}
.reviews {
  min-height: 125px;
  height: auto !important;
  flex-direction: row !important;
  flex-wrap: wrap;
}
.reviews .reviewsItem {
  margin-right: 35px;
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
  width: 250px;
}
.reviewsItem .nameHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-bottom: 15px;
}
.nameHolder .reviewOwner {
  font-size: 15px;
  font-weight: 500;
  padding-top: 5px;
  margin-right: 15px;
  padding-left: 10px;
}
.reviewsItem span {
  font-size: 13px;
  font-weight: 400;
  padding-left: 0px;
}
.review {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.reviewDate {
  align-self: flex-end;
  font-weight: 500;
  color: #a8b0b9;
}
.approvalButtonHolder {
  display: flex;
  align-self: flex-end;
}
.approvalButton {
  width: 35px;
  height: 35px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #fff;
  border: 1px solid #a9a9a9;
  margin-left: 20px;
  cursor: pointer;
}
.approve {
  color: #16dc31;
}
.reject {
  color: #c81e1e;
}
.imagePicker {
  width: 125px;
  height: 125px;
  background-color: #f9f9f9;
  border-radius: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  cursor: pointer;
}
.photoIcon {
  font-size: 50px !important;
}
.imagePicker img {
  width: 115px;
  height: 115px;
  border-radius: 65px;
}
.heightFix {
  min-height: 150px !important;
  height: auto !important;
}
.newNanny {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 13px;
}
.newNanny input[type="text"],
textarea {
  border: 0;
  border-bottom: 1px solid #242d35;
  width: 350px;
  height: 50px;
  padding-left: 10px;
  background-color: transparent;
  color: #242d35;
  font-family: "Montserrat";
  font-size: 14px;
  margin-bottom: 12px;
  margin-right: 50px;
  resize: none;
}
.newNanny input[type="text"]::placeholder,
textarea::placeholder {
  color: #242d35;
  font-family: "Montserrat";
  font-size: 14px;
}
.saveButton {
  width: 350px;
  height: 50px;
  border-radius: 36px;
  background-color: transparent;
  border: 1px solid #242d35;
  font-family: "Montserrat";
  font-size: 14px;
  color: #242d35;
  cursor: pointer;
}
.cancel {
  background-color: #c81e1e;
  color: #f9f9f9;
  border: none;
  font-weight: 700;
  margin-left: 25px;
}
.backButton {
  width: 50px;
  height: 50px;
  background-color: #fafafa;
  border-radius: 25px;
  border: 1px solid #eaecee;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  cursor: pointer;
  padding-right: 7px;
}
.rbc-label {
  font-family: "Montserrat";
  font-size: 14px;
}
.rbc-header span,
.rbc-toolbar .rbc-toolbar-label {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #a9a9a9 !important;
}
.rbc-event-label,
.rbc-event-content {
  font-family: "Montserrat";
  font-size: 13px;
}
.rbc-day-slot .rbc-time-slot {
  background-color: rgba(1, 132, 67, 0.5) !important;
}
.rbc-slot-selection {
  font-family: "Montserrat" !important;
}
.deleteSmall {
  width: 75px;
  height: 40px;
  background-color: #ff0000;
  border: none;
  border-radius: 25px;
  font-family: "Montserrat";
  color: #fff;
  cursor: pointer;
}
.checkbox {
  font-family: "Montserrat";
  font-size: 14px;
  width: 150px;
  cursor: pointer;
}
.checkbox span {
  font-weight: 400 !important;
  padding-left: 5px !important;
}
.infoContainer input[type="password"] {
  border: 0;
  border-bottom: 1px solid #242d35;
  width: 350px;
  height: 50px;
  padding-left: 10px;
  background-color: transparent;
  color: #242d35;
  font-family: "Montserrat";
  font-size: 14px;
  margin-bottom: 12px;
  margin-right: 50px;
  resize: none;
}
.infoContainer input[type="password"]::placeholder {
  color: #242d35;
  font-family: "Montserrat";
  font-size: 14px;
}
.verificationCodeDesc {
  font-family: "Merriweather";
  font-size: 14px;
  color: #fafafa;
  font-weight: 400;
}
.bioFix {
  height: auto !important;
}
.bioFix span {
  font-size: 14px !important;
  text-transform: none !important;
}
.profileImage {
  border-radius: 35px;
  width: 75px;
  height: 75px;
}
.errorMessage {
  color: #c81e1e;
  font-family: "Montserrat";
  font-weight: 500;
  padding-left: 20px;
}
.changeMembership {
  padding: 5px !important;
  border: 1px solid;
  border-radius: 15px;
  margin-left: 10px;
  cursor: pointer;
}
/* pagination */
.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 40px;
  margin-top: 40px;
  align-self: center;
}
.paginationItem {
  width: 35px;
  height: 35px;
  color: #4f5b67;
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.activePagination {
  width: 35px;
  height: 35px;
  background-color: #4f5b67;
  font-family: "Montserrat";
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.nextButton {
  width: 35px;
  height: 35px;
  background-color: #4f5b67;
  font-family: "Montserrat";
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.serviceFix {
  cursor: pointer;
}
.referenceCodeListHeader,
.referenceCodeList {
  display: flex;
  align-items: "center";
  justify-content: "space-between";
}
.referenceCodeListHeader span {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 13px;
  padding-bottom: 10px;
}
.referenceCodeList span {
  font-family: "Montserrat";
  font-size: 13px;
  padding-bottom: 10px;
}
.notificationContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 75%;
  font-family: "Montserrat";
}
.tabs {
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
  font-family: "Montserrat";
}
.tabs .tab {
  margin: 0px 15px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.-activeTab {
  border-bottom: 3px solid #c3c3c3;
}
.showTab {
  display: flex !important;
}
.newNotification {
  display: none;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 50px;
}
.newNotification .notificationInputBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 10%;
}
.newNotification .preview {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40%;
}
.notifications {
  display: none;
  width: 100%;
}
.notificationInputBox .imageContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  border: 1px solid #a8b0b9;
  border-radius: 25px;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  min-height: 75px;
  height: auto;
  padding-top: 30px;
  margin-bottom: 50px;
  margin-top: 25px;
}
.notificationInputBox .checkboxContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 25px);
  border: 1px solid #a8b0b9;
  border-radius: 25px;
  padding-left: 25px;
  position: relative;
  height: 125px;
  padding-top: 30px;
  margin-bottom: 50px;
  margin-top: 25px;
}
.notificationInputBox .checkboxContainer .title,
.notificationInputBox .imageContainer .title {
  position: absolute;
  top: -10px;
  background-color: #fff;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  color: #4f5b67;
  margin: 0;
}
.preview .notificationBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.preview .notificationBox img {
  width: 60px;
  border-radius: 10px;
}
.preview .notificationBox .notificationContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 25px;
}
.notificationContent .title {
  font-weight: 500;
  font-size: 14px;
  color: #242d35;
}
.notificationContent .message {
  font-size: 14px;
  color: #242d35;
  margin-top: 10px;
}
.notificationTestButton {
  height: 55px;
  background-color: white !important;
  border-radius: 5px !important;
  border: 1px solid #000 !important;
  color: #000 !important;
  margin-right: 20px !important;
}
.notificationSendButton {
  background-color: #4040f2 !important ;
  border-radius: 5px !important;
  color: #fff !important;
  height: 55px;
}
.notificationTableContainer {
  min-height: 500px;
  border: 1px solid #e3e3e3;
  border-radius: 25px;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 25px;
}
.notificationTableContainer .header .cell {
  font-size: 15px;
  font-weight: 500;
  color: #242d35;
  font-family: "Montserrat";
}
.notificationTableContainer .content .cell {
  font-family: "Montserrat";
  font-size: 15px;
  color: #242d35;
  font-weight: 400;
}
.removeReviewButton {
  cursor: pointer;
  width: 35px;
  height: 35px;
}
.removeReviewButton svg {
  fill: #ff0000 !important;
}
.more {
  width: 40px;
}
.more svg {
  fill: #999 !important;
}
.jobDetail,
.activityDetail {
  width: 500px;
  position: relative;
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.jobDetail .clientName {
  font-size: 16px;
  color: #242d35;
}
.jobDetail p {
  font-size: 16px;
  color: #242d35;
}
.closeButton {
  position: absolute !important;
  top: 15px;
  right: 15px;
  border: 1px solid #c3c3c3 !important;
  border-radius: 25px !important;
  width: 50px;
  height: 50px;
  min-width: 30px !important;
}
.applicantItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 75px;
  border-bottom: 1px solid #c3c3c3;
  cursor: pointer;
}
.activityDetail p {
  font-size: 18px;
}
.activityDetail span {
  font-size: 16px;
  margin-bottom: 15px;
}
.participantCard {
  display: flex;
  flex-direction: row;
  width: "95%";
  border:1px solid #D79A79;
  border-radius: 20px;
  padding: 15px;
  cursor: pointer;
}
.participantCard span{
  margin-bottom: 0;
}
.participantCard h5{
  margin:0;
  margin-bottom:5px;
  font-size:17px
}
.bold{
  font-weight: 800;
}
.searchContainer {
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.searchButton {
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  border-radius: 8px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: 36px !important;
  margin-left: 20px;
  box-shadow: none !important;
  background-color: #4040f2 !important;
}
.searchButton span {
  color: white !important;
}
.ellipsis {
  width: 250px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 150px;
  height: 100%;
  white-space: nowrap;
}
.jobEdit{
  border:1px solid #D79A79;
  border-radius: 20px;
  position:relative
}